<template>
  <v-btn
    v-if="visible"
    :class="`${classes}`"
    :disabled="disabled"
    :title="lang('ChecklistBuilder.titles.display_log')"
    data-quick-button-action-view="display_log"
    type="button"
    @click="onClick"
    small
  >
    <v-icon>
      mdi-history
    </v-icon>
  </v-btn>
</template>

<script>
import ChecklistBuilder from '@/helpers/ChecklistBuilder.js'

export default {
  name: 'ChecklistCellButtonTypeDisplayLogging',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'data'
  ],
  computed: {
    checklistBuilder() {
      return this.data.ChecklistBuilder
    },
    collection() {
      return this.data.collection
    },
    dataViewer() {
      const builder = this.data.ChecklistBuilder
      return builder.getCellDataViewer(this.checklist.index, this.x, this.y) || {}
    },
    classes() {
      return this.state.classes
    },
    visible() {
      const builder = this.data.ChecklistBuilder
      return !!builder.getSetting('ONLINE')
    },
    disabled() {
      if (this.data.isLog) {
        return true
      }
      if (this.cell.settings.logging_display_never_locked) {
        return false
      }
      return this.state.disabled
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    onClick() {
      if (!this.data.dialogs || !this.data.dialogs.log) {
        return
      }
      const settings = this.checklistBuilder.getSettings()
      const extend = this.checklistBuilder.extend()
      this.data.dialogs.log.settings.data = {}
      this.data.dialogs.log.settings.entries = []
      this.$axios.post('checklist/getCellLog',
        {
          selector: settings.VALUE_SELECTOR,
          cellID: this.cell.id
        }
      ).then((result) => {
        const entries = result.data.data || []
        for (let i = 0, entry; (entry = entries[i]); i++) {
          const data = {
            collection: {},
            collectionBuild: {},
            ChecklistBuilder: {},
            disabled: false,
            correlations: [],
            message: null
          }
          const builder = data.ChecklistBuilder = new ChecklistBuilder(data, {})
          const settingsNew = extend(true, {}, settings || {})
          settingsNew.READ_ONLY = true
          settingsNew.AUTO_SAVE_VARIABLE_VALUES = false
          settingsNew.AUTO_SAVE_CHECKLIST_VALUES = false
          builder.modifySettings(settingsNew)
          const collection = data.collection = extend(true, {}, this.collection || {})
          const checklist = extend(true, {}, this.checklist || {})
          const cell = extend(true, {}, this.cell || {})
          collection['hide_header'] = true
          collection['show_viewer_workflow'] = false
          collection['show_printer_workflow'] = false
          collection.Checklists = [checklist]
          checklist['hide_header'] = true
          checklist.requirement = null
          checklist['required_progress'] = null
          checklist['requirement_matrix'] = null
          checklist['visibility_rights'] = []
          checklist['editability_rights'] = []
          checklist.ChecklistCells = [[cell]]
          checklist.index = 0
          cell.x = 0
          cell.y = 0
          cell['correlation_matrix'] = null
          cell['requirement_count'] = 0
          cell['total_amount_count'] = 0
          cell['field_exclusivity_count'] = 0
          cell['field_lock_count'] = 0
          cell['change_color_count'] = 0
          cell['value_event_trigger_count'] = 0
          cell.settings['value_events'] = []
          delete cell.settings.colspan
          delete cell.settings.rowspan
          const dataViewer = entry.value || {}
          dataViewer.visible = true
          dataViewer.locked = true
          data.correlations = [[[
            dataViewer
          ]]]
          data.emailData = this.data.emailData
          data.replaceEntries = this.data.replaceEntries
          data.mandatory = []
          data.status = [{
            editable: false,
            'mandatory_done': undefined,
            progress: 0,
            'required_by': [],
            visible: true
          }]
          data.isLog = true
          this.data.dialogs.log.settings.data[entry.id] = data
        }
        this.data.dialogs.log.settings.entries = entries
        if (Array.isArray(entries) && entries.length) {
          this.data.dialogs.log.active = true
        }
      })
    }
  }
}
</script>
